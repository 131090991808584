import React from 'react';
import {
    useHistory
  } from "react-router-dom";

const MyPort = () => {
    const His = useHistory()
      React.useEffect(() => {
        document.title = 'MyPort API | CPXDev Data Connect'
    }, [])
    return ( 
        <div className='fade-in-image'>
         <div class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                <img src="https://images.unsplash.com/photo-1453928582365-b6ad33cbcf64?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80" class="d-block w-100" alt="..."/>
                </div>
            </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">MyPort Public API</h5>
                    <h6 class="card-subtitle mb-2 text-muted">Get MyPort via API service</h6>
                    <p class="card-text">Hello, I'm Chinnathorn. I'm a new-gen of Web Developer. And want to share my profile in MyPort website (and also this API). API response data will be same in website but you can use my information to attach with resume to apply job or API learning.</p>
                    <p class="card-text">Please get API profile (Client and SecretPass) <a className='cur' onClick={() => His.push('/')}>here</a> then read API documentation <a href='https://documenter.getpostman.com/view/13721881/Tzz7Py3t' target='_blank'>here</a>.</p>
                </div>
            </div>
        </div>
     );
}
 
export default MyPort;
