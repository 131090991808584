import React from 'react';
import {
    useHistory
  } from "react-router-dom";

const AboutCom = () => {
    const His = useHistory()
      React.useEffect(() => {
        document.title = 'About Us | CPXDev Data Connect'
    }, [])
    return ( 
        <div className='fade-in-image'>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">About CPXDev Data Connect</h5>
                    <h6 class="card-subtitle mb-2 text-muted">If you have any suggestion or feedback. Please contact us with email <a href="mailto:cpxdev@outlook.com?subject=About CPXDev Data Connect">cpxdev@outlook.com</a></h6>
                    <p class="card-text">CPXDev Data Connect is my official public API for education used or use for your public site with non-profit.</p>
                </div>
            </div>
        </div>
       
     );
}
 
export default AboutCom;
