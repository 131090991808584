import React from 'react';
import {
    useHistory
  } from "react-router-dom";

const CGMCom = () => {
    const His = useHistory()
      React.useEffect(() => {
        document.title = 'CGM48 Fan Space API | CPXDev Data Connect'
    }, [])
    return ( 
        <div className='fade-in-image'>
             <div class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                <img src="https://i.scdn.co/image/ab676186000010167c80233d0dee616b1d2296b9" class="d-block w-100" alt="..."/>
                </div>
            </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">CGM48 Fan Space Public API</h5>
                    <h6 class="card-subtitle mb-2 text-muted">Get or search CGM48 member(s) via API service</h6>
                    <p class="card-text">CGM48 is the sister's Idol-group of BNK48 since 2019 (about {new Date().getFullYear() - 2019} years ago). Band location is at Chiangmai, Thailand. The Parent Band called "AKB48" from Japan. Current CGM48 members include 24 members (Latest update in March 2022). CGM48 is managed and imported format undered by Independent Artist Management Co.,Ltd (iAM).</p>
                    <p class="card-text">We developed Public API for knowledge of CGM48 member(s) and easy to use. Please get API profile (Client and SecretPass) <a className='cur' onClick={() => His.push('/')}>here</a> then read API documentation <a href='https://documenter.getpostman.com/view/13721881/UVyrTGGF' target='_blank'>here</a>.</p>
                </div>
            </div>
        </div>
     );
}
 
export default CGMCom;
