import React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import Turnstile, { useTurnstile } from "react-turnstile";

const Home = ({off}) => {
    const turnstile = useTurnstile();
    const [Load, setLoadIco] = React.useState(false)
    const [verify, setVerify] = React.useState('')
    React.useEffect(() => {
        document.title = 'Get Started | CPXDev Data Connect'
    }, [])
    const getAccess = (e) => {
        e.preventDefault()
        if (verify == '') {
            Swal.fire({
                title: 'Client ID and Secret Pass has been generated fail.',
                html: 'Please verify bot verification.',
                icon: 'warning',
            })
            return;
        }
        setLoadIco(true)
        fetch(`https://cpxdevweb.onrender.com/apicenter/getapi?Name=${e.target[0].value}&Email=${e.target[1].value}&token=${verify}`, {method: 'POST'})
          .then(response => response.json())
          .then(data => {
            setLoadIco(false)
            if (data.status == 200) {
                Swal.fire({
                    title: 'Client ID and Secret Pass has been generated successfully.',
                    html: 'Your Client ID is <b>' + data.clientID + "</b>. Don't be forget to check your Secret Pass in your E-mail <b>" + e.target[1].value + "</b> to get access key.",
                    icon: 'success',
                }).then((result) => {
                    e.target.reset()
                    setVerify(false)
                })
            } else {
                Swal.fire({
                    title: 'Something went wrong',
                    html: data.message,
                    icon: 'error',
                }).then((result) => {
                    e.target.reset()
                    setVerify(false)
                })
            }
            turnstile.reset()
          })
          .catch(error => {
            setLoadIco(false)
            Swal.fire({
              title: 'Something went wrong',
              text: 'Unknown error when try to get request to region server. Please conact agent.',
              icon: 'error',
            }).then((result) => {
                setVerify(false)
                turnstile.reset()
            })
          })
      };
    return ( 
        <div className='fade-in-image'>
            <div class="card">
                <div class="card-body">
                    <h2 class="card-title">What is CPXDev Data Connect</h2>
                    <h6 class="card-subtitle mb-2 text-muted">See all API service of CPXDev Data Connect</h6>
                    <p class="card-text">CPXDev Data Connect (former name as "CPX API Center") is a system for publishing public content via a public API, which was developed for study or non-commercial use.</p>
                   <ul class="list-group">
                      <li class="list-group-item active">Please read information below for benefit for use our API</li>
                      <li class="list-group-item">One API Profile includes client ID and Secret Pass. Don't be shared this API Profile to another one.</li>
                      <li class="list-group-item">You need to use API Profile to authorize and generate access token before explore use our API.</li>
                      <li class="list-group-item">Generated Access Token will be expired in one hour. It will return status code as 401 unauthorized if your token is expired and it's time to renew it.</li>
                      <li class="list-group-item">You can use path <code>/home/checkaccessvalid</code> and add header as Access Token to check your access token is valid or not</li>
                      <li class="list-group-item">API Rate Limit includes 60 requests per minute and 5,000 requests per week whole per IP Address.</li>
                    </ul>
                </div>
            </div>
            <div class="card mt-3">
            {off ? (
 <div class="card-body text-center text-danger">
    System is under maintenance. You cannot get API Profile for the moment
    </div>
) : (
    <div class="card-body">
    <h5 class="card-title mb-4">Get API Profile for access all CPXDev Data Connect</h5>
    <h6 class="card-subtitle mb-2 text-muted">Notes: Please enter <b>an active email address</b> to received SecretPass. We are not responsible for any mistakes if you provide an incorrect email address.</h6>
    <form className='pt-3' onSubmit={getAccess}>
        <div className='row'>
            <div className='col-md-5'>
            <div className="mb-3">
                <label for="exampleInputEmail1" className="form-label">Name</label>
                <input type="text" className="form-control" required disabled={Load} />
            </div>
            </div>
            <div className='col-md-5'>
                <label for="exampleInputEmail1" className="form-label">Email</label>
                <input type="email" className="form-control" required disabled={Load} />
            </div>
            <div className='col-md-auto mt-3 pt-3'>
            <button type="submit" className="btn btn-outline-primary" disabled={Load}>{Load ?  <img src="https://d3hhrps04devi8.cloudfront.net/main/cpx-circular.svg" width="15px" /> : <b>Request</b>}</button>
            </div>
            <div className='col-md-10'>
            <Turnstile sitekey='0x4AAAAAAACOr-_T1wr1WRZX' onVerify={(e) => setVerify(e)} onError={() => setVerify('')} onExpire={() => setVerify('')} />
            </div>
        </div>
    </form>
</div>
)}
</div>
               
        </div>
     );
}
 
export default Home;
