import React from 'react';
const Err = () => {
      React.useEffect(() => {
        document.title = '404 Not Found | CPXDev Data Connect'
    }, [])
    return ( 
        <div class="card">
        <div class="card-body">
            <h5 class="card-title">404 Not Found</h5>
            <h6 class="card-subtitle mb-2 text-muted">Click navbar to select page</h6>
        </div>
    </div>
     );
}
 
export default Err;
