import React from 'react';
import {
    useHistory
  } from "react-router-dom";

const BNKCom = () => {
    const His = useHistory()
      React.useEffect(() => {
        document.title = 'BNK48 Fan Space API | CPXDev Data Connect'
    }, [])
    return ( 
        <div className='fade-in-image'>
         <div class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                <img src="https://i.scdn.co/image/ab67618600001016ca53b919b96337ebc6e2ac7b" class="d-block w-100" alt="..."/>
                </div>
            </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">BNK48 Fan Space Public API</h5>
                    <h6 class="card-subtitle mb-2 text-muted">Get or search BNK48 member(s) via API service</h6>
                    <p class="card-text">BNK48 is the famous and popular Idol-group since 2018 (about {new Date().getFullYear() - 2018} years ago). Band location is at Bangkok, Thailand. The Parent Band called "AKB48" from Japan. Current BNK48 members include 56 members and 3 generations (Latest update in March 2022). BNK48 is managed and imported format undered by Independent Artist Management Co.,Ltd (iAM).</p>
                    <p class="card-text">We developed Public API for knowledge of BNK48 member(s) and easy to use. Please get API profile (Client and SecretPass) <a className='cur' onClick={() => His.push('/')}>here</a> then read API documentation <a href='https://documenter.getpostman.com/view/13721881/UVRDHRPR' target='_blank'>here</a>.</p>
                </div>
            </div>
        </div>
       
     );
}
 
export default BNKCom;
