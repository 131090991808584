import React from 'react';
import {
    useHistory
  } from "react-router-dom";

const Cov = () => {
    const His = useHistory()
      React.useEffect(() => {
        document.title = 'Geo API | CPXDev Data Connect'
    }, [])
    return ( 
        <div className='fade-in-image'>
         <div class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                <img src="https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1920&q=100" class="d-block w-100" alt="..."/>
                </div>
            </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Geo List Public API</h5>
                    <h6 class="card-subtitle mb-2 text-muted">Get countries lists and Thailand provinces, distinct and sub-distinct lists for input form via API service</h6>
                    <p class="card-text">We developed Public API for see get countries lists and Thailand provinces, distinct and sub-distinct lists for input form  and easy to use. Please get API profile (Client and SecretPass) <a className='cur' onClick={() => His.push('/')}>here</a> then read API documentation <a href='https://documenter.getpostman.com/view/13721881/UV5WEdZH' target='_blank'>here</a>.</p>
                </div>
            </div>
        </div>
     );
}
 
export default Cov;
