import logo from './logo.svg';
import './App.css';
import React from 'react';
import 'sweetalert2/dist/sweetalert2.min.css'
import {
  BrowserRouter,
  Route,
  Link,
  Switch as BasicSwitch,
  useLocation ,
} from "react-router-dom";
import Swal from 'sweetalert2'

import Home from './page/home'
import Myport from './page/myport'
import Geo from './page/geo'
import Bnk from './page/bnk48'
import Cgm from './page/cgm48'
import About from './page/about'
import Err from './page/err'


var checkloop;
function App() {
  const [status, setStat] = React.useState(0)
  
  const [off, setOff] = React.useState(false)

  const loc = useLocation()
  
   React.useEffect(() => {
    fetch('https://cpxdevweb.onrender.com/apicenter/status', {
      method: 'GET'
    })
      .then((response) => {
          if (response.status == 404) {
            throw new Error('Something went wrong');
          }
           return response.text()
        })
      .then((result) => {}).catch(()=> {
        setOff(true)
      });
   }, []);
  
  
  const uptAPI = () => {
    setStat(0)
    fetch('https://cpxdevconnect.azurewebsites.net/home/status')
    .then(function () {
      setStat(2)
    }).catch(function () {
      // handle error
      setStat(1)
    });
  }
  React.useEffect(() => {
    uptAPI()
    setInterval(function () {
      uptAPI()
    }, 15000);
  }, [])
  return (
    <div>
    <div className="alert alert-primary" role="alert">
      CPXDev Data Connect has been discontinued in October 31, 2024 in 11:59 PM. (UTC +07:00). You can request access token until August 31, 2024 in 11:59 PM. (UTC +07:00).
    </div>
     <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
  <div className="container-fluid">
    <Link className="navbar-brand" to="/">CPXDev Data Connect</Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className={"nav-link" + (useLocation().pathname == '/' ? ' active' : '')} to="/">Get API</Link>
        </li>
        <li className="nav-item">
          <Link className={"nav-link" + (useLocation().pathname == '/myport' ? ' active' : '')} to="/myport">MyPort</Link>
        </li>
        <li className="nav-item">
          <Link className={"nav-link" + (useLocation().pathname == '/geo' ? ' active' : '')} to="/geo">Geo API</Link>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            48 Group Thailand
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link className={"dropdown-item" + (useLocation().pathname == '/bnk48' ? ' active' : '')} to="/bnk48">BNK48</Link></li>
            <li><Link className={"dropdown-item" + (useLocation().pathname == '/cgm48' ? ' active' : '')} to="/cgm48">CGM48</Link></li>
          </ul>
        </li>
        <li className="nav-item">
          <Link className={"nav-link" + (useLocation().pathname == '/about' ? ' active' : '')} to="/about">About</Link>
        </li>
      </ul>
      <span class="navbar-text cur" data-bs-toggle="tooltip" data-bs-placement="top" title= {status == 2 ?'API system is good' : status == 1 ? 'API connection has been lost, please contact developer' : 'Connecting to service'}>
      <a className='nav-link' href='//cp-cpxdevstatus.vercel.app' target='_blank'>{status == 2 ?(<span className='dot bg-success'></span>) : status == 1 ? (<span className='dot bg-warning'></span>) : (<span className='spinner-border spinner-border-sm'></span>)}&nbsp;Service Status</a>
      </span>
    </div>
  </div>
</nav>
<div className='mt-5 container'>
<BasicSwitch>
  <Route exact path="/" render={() => <Home off={off} />} />
  <Route path="/myport" render={() => <Myport />} />
  <Route path="/geo" render={() => <Geo />} />
  <Route path="/bnk48" render={() => <Bnk />} />
  <Route path="/cgm48" render={() => <Cgm />} />
  <Route path="/about" render={() => <About />} />
  <Route component={Err} />
</BasicSwitch>
</div>
<footer className='text-center pt-3 pb-3'>
   Copyright {new Date().getFullYear()} CPXDevStudio, Allright Reserved
</footer>
    </div>
  );
}

export default App;
